import { css } from 'emotion';
import { useGetSelectedFilteredEntityCodes } from 'waypoint-hooks';
import theme from 'config/theme';
import { DisabledDashboard } from 'waypoint-react';
import { AppFeaturePermissions } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import UnitMixCard from './UnitMixCard';

const pageStyle = css`
    display: flex;
    background-color: ${theme.colors.grays.background};
    overflow-y: scroll;
    flex-grow: 1;
    flex-direction: column;
    height: 100%;
`;

const UnitMixContainer = (): JSX.Element => {
    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();

    if (entityCodes.length === 0) {
        return (
            <div className={pageStyle}>
                <DisabledDashboard text={'Loading'} />;
            </div>
        );
    }

    return (
        <div className={pageStyle}>
            <PermissionedWrapper
                featureKey={AppFeaturePermissions.UnitMix}
                showDisabledView={true}
            >
                <div style={{ padding: '12px' }}>
                    <UnitMixCard entityCodes={entityCodes} />
                </div>
            </PermissionedWrapper>
        </div>
    );
};

export default UnitMixContainer;
