import { useState } from 'react';
import { useGetUnitMix, useRecurringCharges } from 'waypoint-hooks';
import { buildWeightedAverageDevExtremeConfig } from 'waypoint-utils/dev-extreme/weighted-averages';
import { SelectedDataLevel } from 'waypoint-types';
import { safeDivision } from '../waypoint-utils';

interface UnitMix {
    entityCodes: string[];
    selectedDataLevel: SelectedDataLevel;
}

const WEITGHED_METRICS = [
    'avg_sf',
    'average_total_monthly',
    'average_total_monthly_psf',
];

export const useUnitMix = ({ entityCodes, selectedDataLevel }: UnitMix) => {
    const [unitMix] = useState({});

    const {
        data: recurringChargesData,
        isLoading: isLoadingRecurringCharges,
        isError: isErrorRecurringCharges,
    } = useRecurringCharges(entityCodes, selectedDataLevel);

    const {
        data: unitMixData,
        isLoading: isUnitMixLoading,
        isError: isUnitMixError,
    } = useGetUnitMix(entityCodes, selectedDataLevel);

    const { calculateCustomSummary: weightedCalc } =
        buildWeightedAverageDevExtremeConfig(
            [
                {
                    metricKey: 'avg_sf',
                    scaleKey: 'number_of_units',
                    options: {
                        valueFormat: { type: 'number', precision: 1 },
                    },
                },
                {
                    metricKey: 'average_total_monthly',
                    scaleKey: 'occupied_units',
                    options: {
                        valueFormat: { type: 'number', precision: 1 },
                    },
                },
                {
                    metricKey: 'average_total_monthly_psf',
                    scaleKey: 'occupied_sf',
                    options: {
                        valueFormat: { type: 'number', precision: 1 },
                    },
                },
            ],
            {
                displayFormat: '{0}',
            },
        );

    const calculateCustomSummary = (options: {
        name: string;
        component: any;
        summaryProcess: string;
        value: any;
        totalValue: number;
        groupIndex: number;
        totalDisplayValue?: {
            total_units: number;
            available_to_rent: number;
            leased_units: number;
            leased_percentage: number;
            occupancy_percentage: number;
            occupied_units: number;
        };
    }) => {
        if (WEITGHED_METRICS.includes(options.name)) {
            weightedCalc(options);
        } else {
            switch (options.summaryProcess) {
                case 'start': {
                    options.totalValue = 0;
                    options.totalDisplayValue = {
                        total_units: 0,
                        available_to_rent: 0,
                        leased_units: 0,
                        leased_percentage: 0,
                        occupancy_percentage: 0,
                        occupied_units: 0,
                    };
                    break;
                }
                case 'calculate': {
                    if (options.totalDisplayValue) {
                        options.totalDisplayValue.leased_units += Number(
                            options.value.leased_units,
                        );
                        options.totalDisplayValue.total_units += Number(
                            options.value.number_of_units,
                        );
                        options.totalDisplayValue.available_to_rent += Number(
                            options.value.available_to_rent,
                        );
                        options.totalDisplayValue.occupancy_percentage +=
                            Number(options.value.available_to_rent);
                        options.totalDisplayValue.occupied_units += Number(
                            options.value.occupied_units,
                        );
                    }
                    break;
                }
                case 'finalize': {
                    if (
                        options.name === 'leased_percentage' &&
                        options.totalDisplayValue
                    ) {
                        options.totalValue = safeDivision(
                            options.totalDisplayValue.leased_units,
                            options.totalDisplayValue.total_units,
                        );
                    }

                    if (
                        options.name === 'atr_by_unit' &&
                        options.totalDisplayValue
                    ) {
                        options.totalValue = safeDivision(
                            options.totalDisplayValue.available_to_rent,
                            options.totalDisplayValue.total_units,
                        );
                    }

                    if (
                        options.name === 'occupancy_percentage' &&
                        options.totalDisplayValue
                    ) {
                        options.totalValue = safeDivision(
                            options.totalDisplayValue.occupied_units,
                            options.totalDisplayValue.total_units,
                        );
                    }
                }
            }
        }
    };

    return {
        unitMix,
        calculateCustomSummary,
        isLoading: isLoadingRecurringCharges && isUnitMixLoading,
        isError: isErrorRecurringCharges || isUnitMixError,
        recurringChargesData,
        unitMixData,
    };
};
