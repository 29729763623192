import {
    currencyRenderer,
    integerRenderer,
    percentageRenderer,
} from 'utils/tables/renderers';
import {
    ColumnDescriptor,
    ExportableGridSummaryFormatter,
    SavedConfigColumn,
    applySavedConfigSortingToGridData,
    applySavedFiltersToGridData,
    convertColumnsToAntd,
    getColumnTotalCells,
    getTableOrGroupSummaryData,
    groupHeaderRowClass,
    headerClass,
    rowClass,
    sortDataByColumnKey,
} from './GridExportConversionUtils';
import { Table } from 'antd';
import { Dictionary } from 'ts-essentials';
import { SavedConfiguration, TransformedUnitMix } from 'waypoint-types';
import { DASH_DASH } from 'config/constants';
import { useMemo, useState } from 'react';

export interface UnitMixWithGroupHeaderFlag extends TransformedUnitMix {
    isGroupHeader?: boolean;
    children?: UnitMixWithGroupHeaderFlag[];
}

const bedroomCountToStudio = (value: number) => {
    if (value === 0) {
        return 'Studio';
    }

    return value;
};

const unitTypeRender = (value: number) => {
    return value ? value : DASH_DASH;
};

export const unitMixBaseColumns: ColumnDescriptor[] = [
    {
        title: 'Beds',
        dataIndex: 'bedroom_count',
        key: 'bedroom_count',
        dataType: 'number',
        align: 'center',
        render: bedroomCountToStudio,
    },
    {
        title: 'Unit Type',
        dataIndex: 'unit_type',
        key: 'unit_type',
        dataType: 'string',
        align: 'left',
        width: '180px',
        render: unitTypeRender,
    },
    {
        title: 'Avg SF',
        dataIndex: 'avg_sf',
        key: 'avg_sf',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
    {
        title: 'Total SF',
        dataIndex: 'total_sf',
        key: 'total_sf',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
    {
        title: 'Total Occupied',
        dataIndex: 'occupied_units',
        key: 'occupied_units',
        dataType: 'number',
        align: 'center',
    },
    {
        title: 'Total Vacant',
        dataIndex: 'vacant_units',
        key: 'vacant_units',
        dataType: 'number',
        align: 'center',
    },
    {
        title: 'Total Units',
        dataIndex: 'number_of_units',
        key: 'number_of_units',
        dataType: 'number',
        align: 'center',
    },
    {
        title: 'Occ % (By Unit)',
        dataIndex: 'occupancy_percentage',
        key: 'occupancy_percentage',
        dataType: 'number',
        align: 'center',
        render: percentageRenderer,
    },
    {
        title: '% of Total (By Units)',
        dataIndex: 'percent_of_total_units',
        key: 'percent_of_total_units',
        dataType: 'number',
        align: 'center',
        render: percentageRenderer,
    },
    {
        title: 'Non-Rev Units',
        dataIndex: 'non_revenue_units',
        key: 'non_revenue_units',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
    {
        title: 'Occ. Notice',
        dataIndex: 'occupied_notice',
        key: 'occupied_notice',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
    {
        title: 'Notice Avail.',
        dataIndex: 'notice_available',
        key: 'notice_available',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
    {
        title: 'Vacant Avail.',
        dataIndex: 'vacant_unrented',
        key: 'vacant_unrented',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
    {
        title: 'ATR',
        dataIndex: 'available_to_rent',
        key: 'available_to_rent',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
    {
        title: 'ATR %',
        dataIndex: 'atr_by_unit',
        key: 'atr_by_unit',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
    {
        title: 'Occ. No Notice',
        dataIndex: 'occupied_no_notice',
        key: 'occupied_no_notice',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
    {
        title: 'Notice Rented',
        dataIndex: 'notice_rented',
        key: 'notice_rented',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
    {
        title: 'Vacant Rented',
        dataIndex: 'vacant_rented',
        key: 'vacant_rented',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
    {
        title: 'Leased',
        dataIndex: 'leased_units',
        key: 'leased_units',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
    {
        title: 'Leased %',
        dataIndex: 'leased_percentage',
        key: 'leased_percentage',
        dataType: 'number',
        align: 'center',
        render: percentageRenderer,
    },
    {
        title: 'Avg Monthly Charges',
        dataIndex: 'average_total_monthly',
        key: 'average_total_monthly',
        dataType: 'number',
        align: 'center',
        width: '90px',
        render: currencyRenderer,
    },
    {
        title: 'Avg Charges PSF',
        dataIndex: 'average_total_monthly_psf',
        key: 'average_total_monthly_psf',
        dataType: 'number',
        align: 'center',
        render: currencyRenderer,
    },
    {
        title: 'Monthly Charge Total',
        dataIndex: 'total_monthly',
        key: 'total_monthly',
        dataType: 'number',
        align: 'center',
        width: '95px',
        render: currencyRenderer,
    },
    {
        title: '% of Total Charges',
        dataIndex: 'percent_of_avg_monthly_charges',
        key: 'percent_of_avg_monthly_charges',
        dataType: 'number',
        align: 'center',
        render: percentageRenderer,
    },
    {
        title: 'Exp. 0-30 days',
        dataIndex: 'next30',
        key: 'next30',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
    {
        title: 'Exp. 31-60 days',
        dataIndex: 'next60',
        key: 'next60',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
    {
        title: 'Exp. 61-90 days',
        dataIndex: 'next90',
        key: 'next60',
        dataType: 'number',
        align: 'center',
        render: integerRenderer,
    },
];

const unitMixSummaryFormatters: Dictionary<ExportableGridSummaryFormatter> = {
    bedroom_count: {
        summaryType: 'sum',
        render: () => 'Total / Avg',
    },
    number_of_units: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    occupied_units: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    vacant_units: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    occupancy_percentage: {
        summaryType: 'custom',
        render: (value: number) => percentageRenderer(value),
    },
    occupied_notice: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    occupied_no_notice: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    notice_rented: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    vacant_unrented: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    vacant_rented: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    available_to_rent: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    leased_units: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    atr_by_unit: {
        summaryType: 'custom',
        render: (value: number) => percentageRenderer(value),
    },
    next30: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    next60: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    next90: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    notice_available: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    non_revenue_units: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    leased_percentage: {
        summaryType: 'custom',
        render: (value: number) => percentageRenderer(value),
    },
    avg_sf: {
        summaryType: 'custom',
        render: (value: number) => integerRenderer(value, 0),
    },
    total_sf: {
        summaryType: 'sum',
        render: (value: number) => integerRenderer(value, 0),
    },
    percent_of_total_units: {
        summaryType: 'sum',
        render: percentageRenderer,
    },
    average_total_monthly: {
        summaryType: 'custom',
        render: currencyRenderer,
    },
    average_total_monthly_psf: {
        summaryType: 'custom',
        render: currencyRenderer,
    },
    total_monthly: {
        summaryType: 'sum',
        render: currencyRenderer,
    },
    percent_of_avg_monthly_charges: {
        summaryType: 'sum',
        render: percentageRenderer,
    },
};

const calculateCustomSummary = (column: string, data: TransformedUnitMix[]) => {
    const weightedAverageMetrics = [
        'avg_sf',
        'average_total_monthly',
        'average_total_monthly_psf',
    ];

    if (weightedAverageMetrics.includes(column)) {
        const totalUnits = data.reduce(
            (total, item) => total + item.number_of_units,
            0,
        );
        const totalMetric = data.reduce((total, item) => {
            const metricValue = item[column as keyof TransformedUnitMix];
            return typeof metricValue === 'number'
                ? total + metricValue * item.number_of_units
                : total;
        }, 0);
        return totalUnits ? totalMetric / totalUnits : 0;
    }

    if (column === 'total_sf') {
        return data.reduce((total, item) => total + item.total_sf, 0);
    }

    const {
        totalUnits,
        totalLeasedUnits,
        totalOccupiedUnits,
        totalAvailableToRent,
    } = data.reduce(
        (acc, item) => {
            acc.totalUnits += item.number_of_units;
            acc.totalLeasedUnits += item.leased_units;
            acc.totalOccupiedUnits += item.occupied_units;
            acc.totalAvailableToRent += item.available_to_rent;
            return acc;
        },
        {
            totalUnits: 0,
            totalLeasedUnits: 0,
            totalOccupiedUnits: 0,
            totalAvailableToRent: 0,
        },
    );

    switch (column) {
        case 'leased_percentage':
            return totalUnits ? totalLeasedUnits / totalUnits : 0;
        case 'occupancy_percentage':
            return totalUnits ? totalOccupiedUnits / totalUnits : 0;
        case 'atr_by_unit':
            return totalUnits ? totalAvailableToRent / totalUnits : 0;
    }

    return 0;
};

const unitMixGroupTreeBuilder = (
    unitMix: TransformedUnitMix[],
    gridColumns: ColumnDescriptor[],
    groupingKeys: (keyof TransformedUnitMix)[],
    blankFields: string[] = [],
    savedConfigColumns?: Dictionary<SavedConfigColumn>,
): UnitMixWithGroupHeaderFlag[] => {
    if (!groupingKeys.length) {
        return unitMix;
    }

    const groupingKey = groupingKeys[0];
    const groupedUnitMix = unitMix.reduce(
        (dict, unitMix: TransformedUnitMix) => {
            const unitMixField = unitMix[groupingKey]?.toString() ?? '';

            if (!Object.keys(dict).includes(unitMixField)) {
                dict[unitMixField] = [unitMix];
                return dict;
            }
            dict[unitMixField].push(unitMix);
            return dict;
        },
        {} as Dictionary<TransformedUnitMix[]>,
    );

    const groupedUnitMixData: Partial<UnitMixWithGroupHeaderFlag>[] = [];
    for (const [key, entry] of Object.entries(groupedUnitMix)) {
        const groupRow = {
            [groupingKey]: key,
            isGroupHeader: true,
        };

        const groupTotals = getTableOrGroupSummaryData(
            entry,
            gridColumns,
            unitMixSummaryFormatters,
            calculateCustomSummary,
        );

        const innerRowBlankFields = blankFields.reduce((dict, bf) => {
            dict[bf] = '';
            return dict;
        }, {} as Dictionary<string>);

        groupedUnitMixData.push({
            ...groupTotals,
            ...innerRowBlankFields,
            ...groupRow,
            children:
                groupingKeys.length > 1
                    ? unitMixGroupTreeBuilder(
                          entry,
                          gridColumns,
                          groupingKeys.slice(1),
                          groupingKeys,
                          savedConfigColumns,
                      )
                    : entry.map((unitMix: TransformedUnitMix) => {
                          return {
                              ...unitMix,
                              ...innerRowBlankFields,
                          };
                      }),
        });
    }

    const dataType =
        gridColumns.find((gc) => gc.dataIndex === groupingKey)?.dataType ??
        'string';

    const sortOrder = savedConfigColumns
        ? (savedConfigColumns[groupingKey]?.sortOrder ?? 'asc')
        : 'asc';

    return sortDataByColumnKey(
        dataType,
        groupingKey,
        sortOrder,
        groupedUnitMixData as UnitMixWithGroupHeaderFlag[],
    ) as UnitMixWithGroupHeaderFlag[];
};

interface UnitMixExportableGridProps {
    unitMix: TransformedUnitMix[];
    savedConfig: SavedConfiguration | null;
    setIsReadyForPDFExport: (value: boolean) => void;
}

export const UnitMixExportableGrid = ({
    unitMix,
    savedConfig,
    setIsReadyForPDFExport,
}: UnitMixExportableGridProps): JSX.Element => {
    const sortedByStudioBedroomCountFirst = (unitMix: TransformedUnitMix[]) =>
        unitMix.sort((a: TransformedUnitMix, b: TransformedUnitMix) => {
            if (a.bedroom_count === 'Studio') {
                return -1;
            }

            if (b.bedroom_count === 'Studio') {
                return 1;
            }

            return (a?.bedroom_count ?? 0) - (b?.bedroom_count ?? 0);
        });

    const [filteredUnitMix, setFilteredUnitMix] = useState<
        TransformedUnitMix[] | undefined
    >(undefined);

    const { gridColumns, groupedColumnNames, columnsForFilteringAndSorting } =
        convertColumnsToAntd(unitMixBaseColumns, false, savedConfig);

    useMemo(async () => {
        if (!savedConfig?.filters_json?.grid_config?.filterValue) {
            setFilteredUnitMix(unitMix);
            return;
        }
        const filteredData = await applySavedFiltersToGridData(
            unitMix,
            savedConfig,
            columnsForFilteringAndSorting,
            true,
        );
        setFilteredUnitMix(filteredData);
    }, [unitMix]);

    if (!filteredUnitMix) {
        return <></>;
    }

    const sortedUnitMix = applySavedConfigSortingToGridData(
        sortedByStudioBedroomCountFirst(filteredUnitMix),
        columnsForFilteringAndSorting,
        savedConfig,
    );

    const processedGridData = unitMixGroupTreeBuilder(
        sortedUnitMix as TransformedUnitMix[],
        columnsForFilteringAndSorting,
        groupedColumnNames as (keyof TransformedUnitMix)[],
        groupedColumnNames,
        savedConfig?.filters_json?.grid_config?.columns,
    );

    setIsReadyForPDFExport(true);

    return (
        <Table
            key={processedGridData.length}
            dataSource={processedGridData as UnitMixWithGroupHeaderFlag[]}
            size="small"
            columns={gridColumns}
            pagination={false}
            bordered={true}
            className={headerClass}
            rowClassName={(row) => {
                return row?.isGroupHeader ? groupHeaderRowClass : rowClass;
            }}
            expandable={{
                defaultExpandAllRows:
                    savedConfig?.filters_json?.local_config?.expanded ?? false,
                expandIcon: () => {
                    return null;
                },
            }}
            summary={() =>
                getColumnTotalCells(
                    gridColumns,
                    sortedUnitMix,
                    unitMixSummaryFormatters,
                    calculateCustomSummary,
                )
            }
        />
    );
};
