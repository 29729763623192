import {
    RecurringCharge,
    RentRollProps,
    TransformedUnitMix,
} from 'waypoint-types';
import { safeDivision } from 'waypoint-utils';
import {
    getRecurringChargeByRentRoll,
    isRecurringChargeLease,
    isUnitOccupied,
} from 'components/leases/components/recurring-charge/utils';

export const rentRollSortByGroupingOptions = [
    {
        value: 'leasable_space_code',
        label: 'Units',
    },
    {
        value: 'source_system_lease_code',
        label: 'Lease Code',
    },
    {
        value: 'tenant_name',
        label: 'Tenants',
    },
    {
        value: 'lease_term_months',
        label: 'Term (Months)',
    },
    {
        value: 'remaining_months',
        label: 'Mos. Remaining',
    },
    {
        value: 'total_monthly',
        label: 'Monthly',
    },
    {
        value: 'total_annual',
        label: 'Annual',
    },
    {
        value: 'rentable_sq_ft',
        label: 'Rentable SF',
    },
    {
        value: 'total_monthly_per_sq_ft',
        label: 'Monthly / SF',
    },
    {
        value: 'total_annual_per_sq_ft',
        label: 'Annual / SF',
    },
];

export const customSummaryDataFields = [
    'total_property_name',
    'total_tenants',
    'total_leases',
    'total_units',
    'total_rent_monthly',
    'group_source_system_lease_code',
    'group_tenants',
    'group_properties',
];

export const customCalculationFields = [
    'total_cam_annual_per_sq_ft',
    'total_cam_monthly_per_sq_ft',
    'total_taxes_insurance_monthly_per_sq_ft',
    'total_taxes_insurance_annual_per_sq_ft',
    'total_other_monthly_per_sq_ft',
    'total_other_annual_per_sq_ft',
    'total_rent_annual_per_sq_ft',
    'total_rent_monthly_per_sq_ft',
    'total_monthly_per_sq_ft',
    'total_annual_per_sq_ft',
    'group_cam_annual_per_sq_ft',
    'group_cam_monthly_per_sq_ft',
    'group_taxes_insurance_monthly_per_sq_ft',
    'group_taxes_insurance_annual_per_sq_ft',
    'group_other_monthly_per_sq_ft',
    'group_other_annual_per_sq_ft',
    'group_rent_annual_per_sq_ft',
    'group_rent_monthly_per_sq_ft',
    'group_monthly_per_sq_ft',
    'group_annual_per_sq_ft',
];

export const recurringChargeBuckets = [
    'cam',
    'taxes_insurance',
    'rent',
    'other',
];

export enum RentRollColumnSelection {
    ConsolidatedCharges = 'consolidatedCharges',
    ChargeGroup = 'chargeGroup',
}

export const RENT_ROLL_DEFAULT_COLUMN_SELECTION =
    RentRollColumnSelection.ConsolidatedCharges;

export const rentRollDefaultConfigs = [
    {
        label: 'Consolidated Charges',
        key: RentRollColumnSelection.ConsolidatedCharges,
    },
    {
        label: 'By Charge Category',
        key: RentRollColumnSelection.ChargeGroup,
    },
];

export const calculateTotalMonthlyCharge = (
    rentRoll: RentRollProps | TransformedUnitMix,
    recurringCharges: RecurringCharge[],
) => {
    return recurringCharges
        .filter((rc) => isRecurringChargeLease(rc, rentRoll))
        .reduce((acc: number, curr: RecurringCharge) => {
            return acc + curr.monthly;
        }, 0);
};

export const rentRollWithTotalCharges = (
    rentRoll: RentRollProps[] | TransformedUnitMix[],
    recurringCharges: RecurringCharge[],
): any => {
    return rentRoll.map((rr) => {
        const totalMonthly = calculateTotalMonthlyCharge(rr, recurringCharges);
        return {
            ...rr,
            total_monthly: isUnitOccupied(rr) ? totalMonthly : null,
            total_annual: isUnitOccupied(rr) ? totalMonthly * 12 : null,
            total_monthly_per_sq_ft: isUnitOccupied(rr)
                ? safeDivision(totalMonthly, rr.rentable_sq_ft)
                : null,
            total_annual_per_sq_ft: isUnitOccupied(rr)
                ? safeDivision(totalMonthly * 12, rr.rentable_sq_ft)
                : null,
            ...calculateUnitBucketCharges(rr, recurringCharges),
        };
    });
};

export const calculateUnitBucketCharges = (
    rentRoll: RentRollProps | TransformedUnitMix,
    recurringCharges: RecurringCharge[],
) => {
    const unitRecurringCharges = getRecurringChargeByRentRoll(
        recurringCharges,
        rentRoll,
    );
    const allBuckets: any = {};
    for (const bucket of recurringChargeBuckets) {
        allBuckets[bucket + '_annual'] = 0;
        allBuckets[bucket + '_annual_per_sq_ft'] = 0;
        allBuckets[bucket + '_monthly'] = 0;
        allBuckets[bucket + '_monthly_per_sq_ft'] = 0;
    }

    for (const recurringCharge of unitRecurringCharges) {
        const key = recurringCharge.bucket_code;
        allBuckets[key + '_annual'] += recurringCharge.annual;
        allBuckets[key + '_annual_per_sq_ft'] +=
            recurringCharge.annual_per_sq_ft;
        allBuckets[key + '_monthly'] += recurringCharge.monthly;
        allBuckets[key + '_monthly_per_sq_ft'] +=
            recurringCharge.monthly_per_sq_ft;
    }

    return allBuckets;
};

interface GroupedDatGrid {
    key: string;
    count: number;
    items: GroupedDatGrid[] | null;
    summary: number[];
    groupSummary: {
        leasable_space_code: string[];
        lease_code: string[];
    };
}

const extractGroupSummaries = (
    data: GroupedDatGrid,
): {
    leasable_space_code: string[];
    lease_code: string[];
} => {
    const groupSummaries: {
        leasable_space_code: string[];
        lease_code: string[];
    } = {
        leasable_space_code: [],
        lease_code: [],
    };

    function traverse(items: any[]): void {
        for (const item of items) {
            if (item.groupSummary) {
                const { leasable_space_code, lease_code } = item.groupSummary;
                groupSummaries.leasable_space_code.push(...leasable_space_code);
                groupSummaries.lease_code.push(...lease_code);
            }

            if (item.items && item.items.length > 0) {
                traverse(item.items);
            }
        }
    }

    if (data.items && data.items.length > 0) {
        traverse(data.items);
    }

    if (!data.items?.length && data.groupSummary) {
        const { leasable_space_code, lease_code } = data.groupSummary;
        groupSummaries.leasable_space_code.push(...leasable_space_code);
        groupSummaries.lease_code.push(...lease_code);
    }

    return groupSummaries;
};

const calculateSummary = (
    groupSummary: {
        leasable_space_code: string[];
        lease_code: string[];
    },
    recurringCharges: RecurringCharge[],
    totalRentableSqFt: number,
): any => {
    const chargeForGroup = recurringCharges.filter((rc) => {
        return (
            groupSummary.leasable_space_code.includes(rc.leasable_space_code) &&
            groupSummary.lease_code.includes(rc.lease_code)
        );
    });
    const totalMonthly = chargeForGroup.reduce(
        (acc: number, curr: RecurringCharge) => {
            return acc + curr.monthly;
        },
        0,
    );
    const initialAccumulator = recurringChargeBuckets.reduce(
        (acc, bucket) => {
            acc[bucket + '_annual'] = 0;
            acc[bucket + '_annual_per_sq_ft'] = 0;
            acc[bucket + '_monthly'] = 0;
            acc[bucket + '_monthly_per_sq_ft'] = 0;
            return acc;
        },
        {} as Record<string, number>,
    );

    const bucketTotals = chargeForGroup.reduce(
        (acc: Record<string, number>, curr: RecurringCharge) => {
            const bucket = curr.bucket_code;
            acc[bucket + '_annual'] += curr.annual;
            acc[bucket + '_annual_per_sq_ft'] += curr.annual_per_sq_ft;
            acc[bucket + '_monthly'] += curr.monthly;
            acc[bucket + '_monthly_per_sq_ft'] += curr.annual_per_sq_ft / 12;
            return acc;
        },
        initialAccumulator,
    );

    return [
        safeDivision(totalMonthly, totalRentableSqFt),
        totalMonthly * 12,
        safeDivision(totalMonthly * 12, totalRentableSqFt),
        totalMonthly,
        bucketTotals.rent_monthly,
        bucketTotals.rent_monthly_per_sq_ft,
        bucketTotals.rent_annual,
        bucketTotals.rent_annual_per_sq_ft,
        bucketTotals.cam_monthly,
        bucketTotals.cam_monthly_per_sq_ft,
        bucketTotals.cam_annual,
        bucketTotals.cam_annual_per_sq_ft,
        bucketTotals.taxes_insurance_monthly,
        bucketTotals.taxes_insurance_monthly_per_sq_ft,
        bucketTotals.taxes_insurance_annual,
        bucketTotals.taxes_insurance_annual_per_sq_ft,
        bucketTotals.other_monthly,
        bucketTotals.other_monthly_per_sq_ft,
        bucketTotals.other_annual,
        bucketTotals.other_annual_per_sq_ft,
    ];
};

const recursiveCalculateSummary = (
    group: GroupedDatGrid,
    recurringCharges: RecurringCharge[],
): void => {
    if (group.items && group.items.length > 0) {
        for (const subItem of group.items) {
            recursiveCalculateSummary(subItem, recurringCharges);
        }
    }

    const groupSummary = extractGroupSummaries(group);

    // The summary in the group is the total of the children
    // The total is are array of numbers
    // For the array order check libs/api/utils/src/lib/db-query.ts::calculateSummary
    const totalRentableSqFt = group.summary[4];
    group.summary = [
        ...group.summary,
        ...calculateSummary(groupSummary, recurringCharges, totalRentableSqFt),
    ];
};

export const groupByGridFields = (
    groupedRentRoll: GroupedDatGrid[],
    recurringCharges: RecurringCharge[],
): GroupedDatGrid[] => {
    try {
        return groupedRentRoll.map((rr) => {
            recursiveCalculateSummary(rr, recurringCharges);
            return rr;
        });
    } catch (e) {
        console.error(e);
        return [];
    }
};
