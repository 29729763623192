import { Button, Card } from 'antd';
import AgedReceivablesTable from './AgedReceivablesTable';
import AgedReceivablesSummary from './AgedReceivablesSummary';
import { useGetDelinquency, useGetGroupableAttributes } from 'waypoint-hooks';
import { DisabledDashboard, TextLoadingBar } from 'waypoint-react';
import { css } from 'emotion';
import { useEffect, useState } from 'react';
import theme from 'config/theme';
import { PieChartOutlined } from '@ant-design/icons';
import { numericalSort } from 'utils/tables/sorters';
import { DelinquencyData, SelectedDataLevel } from 'waypoint-types';
import { AttributesGroupBySelect } from 'components/attributesGroupBySelect/AttributesGroupBySelect';
import { EntityDataGroupingKeys } from 'utils/EntityDataGroupingConstants';
import { AppFeaturePermissions, AttributeFromAPI } from 'contexts';
import { PermissionedWrapper } from 'components/permissionGroups/PermissionedWrapper';
import AgedReceivablesDatePicker from './AgedReceivablesDatePicker';

interface AgedReceivablesCardProps {
    entityCodes: string[];
    selectedDataLevel: SelectedDataLevel;
    isPropertyProfilePage: boolean;
    rollupPeriod?: string;
}

export const AGED_RECEIVABLES_CARD_TYPE = 'agedReceivables';

const cardTitle = css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
`;

const textLoadingStyle = {
    height: '12px',
    width: '150px',
    marginTop: '0px',
};

const chartButtonStyle = css`
    color: ${theme.colors.grays.light} !important;
    border-color: ${theme.colors.grays.light} !important;
    background: ${theme.colors.white} !important;
    text-shadow: none !important;
`;

const chartButtonStyleActive = css`
    border-color: ${theme.colors.blues.focusRow} !important;
    background: ${theme.colors.blues.focusRow} !important;
    color: ${theme.colors.blues.antBlue} !important;
    text-shadow: none !important;
`;

const AgedReceivablesCard = ({
    entityCodes,
    selectedDataLevel,
    isPropertyProfilePage,
    rollupPeriod,
}: AgedReceivablesCardProps) => {
    const groupableAttributes = useGetGroupableAttributes();

    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

    const [groupingSelection, setGroupingSelection] =
        useState<EntityDataGroupingKeys>(EntityDataGroupingKeys.Property);

    const [attributeSelection, setAttributeSelection] =
        useState<AttributeFromAPI | null>(groupableAttributes[0] ?? null);

    const [period, setPeriod] = useState<string | undefined>(rollupPeriod);

    const {
        data: delinquencies,
        isLoading: isDelinquencyLoading,
        isError: isDelinquencyError,
    } = useGetDelinquency(
        entityCodes,
        selectedDataLevel,
        isPropertyProfilePage,
        period,
    );

    useEffect(() => {
        setAttributeSelection(groupableAttributes?.[0] ?? null);
    }, [groupableAttributes]);

    if (!delinquencies || isDelinquencyError) {
        return <DisabledDashboard text={'Loading'} />;
    }

    const { chart_table_data, master_table_data } = delinquencies;

    const sortedMasterTableData = master_table_data.sort(
        (a: DelinquencyData, b: DelinquencyData) =>
            numericalSort(b.total, a.total),
    );

    const sortedChartTableData = chart_table_data.sort(
        (a: DelinquencyData, b: DelinquencyData) =>
            numericalSort(b.total, a.total),
    );

    return (
        <PermissionedWrapper featureKey={AppFeaturePermissions.AgedReceivables}>
            <Card
                data-testid="aged-receivables-card"
                loading={isDelinquencyLoading}
                title={
                    isDelinquencyLoading ? (
                        <div className={cardTitle}>
                            <TextLoadingBar style={textLoadingStyle} />
                        </div>
                    ) : (
                        <div className={cardTitle}>
                            <h2
                                style={{
                                    margin: '0 10px 0 0',
                                }}
                            >
                                Aged Receivables
                            </h2>
                            {!isPropertyProfilePage && (
                                <>
                                    <span style={{ margin: '0 12px' }}>
                                        Grouped By
                                    </span>
                                    <div
                                        style={{
                                            marginLeft: '0px',
                                        }}
                                    >
                                        <AttributesGroupBySelect
                                            key={`${groupingSelection}_${
                                                attributeSelection?.key ?? ''
                                            }`}
                                            groupingSelection={
                                                groupingSelection
                                            }
                                            setGroupingSelection={
                                                setGroupingSelection
                                            }
                                            attributeSelection={
                                                attributeSelection
                                            }
                                            attributes={groupableAttributes}
                                            setAttributeSelection={
                                                setAttributeSelection
                                            }
                                        />
                                    </div>
                                </>
                            )}
                            <span style={{ margin: '0 12px' }}>Through</span>
                            <AgedReceivablesDatePicker
                                period={period}
                                setPeriod={setPeriod}
                            />
                        </div>
                    )
                }
                extra={
                    <Button
                        type="primary"
                        className={
                            isCollapsed
                                ? chartButtonStyle
                                : chartButtonStyleActive
                        }
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        <PieChartOutlined style={{ fontSize: '20px' }} />
                    </Button>
                }
            >
                <div
                    style={{
                        height: isCollapsed ? '0' : '400px',
                        transition:
                            'height 0.2s ease-in-out, min-height 0.2s ease-in-out, padding 0.2s ease-in-out',
                        overflow: 'hidden',
                    }}
                >
                    <AgedReceivablesSummary
                        chartTableData={sortedChartTableData}
                    />
                </div>
                <AgedReceivablesTable
                    delinquencies={sortedMasterTableData}
                    entityCodes={entityCodes}
                    isPropertyProfilePage={isPropertyProfilePage}
                    attributeSelection={attributeSelection}
                    groupingSelection={groupingSelection}
                    selectedDataLevel={selectedDataLevel}
                    period={period}
                />
            </Card>
        </PermissionedWrapper>
    );
};

export default AgedReceivablesCard;
