import React, { useEffect, useState } from 'react';
import App from 'components/app/App';
import {
    HashRouter as Router,
    Redirect,
    Route,
    Switch,
} from 'react-router-dom';
import Dashboard from 'components/analytics/portfolioSegmentation/dashboard/Dashboard';
import ErrorPage from 'components/error/Error';
import NotFound from 'components/error/NotFound';
import SettingsRoutes from 'routes/settings';
import { clearCookie } from 'utils/cookie';
import Layout from 'components/layout/Layout';
import { ExpirationDashboard } from 'components/leases/expirations';
import AttributesDashboard from 'components/attributes/dashboard/AttributesDashboard';
import { RouteUrls } from 'routes/RouteConstants';
import AnalyticsContainer from 'components/analytics/AnalyticsContainer';
import FinancialOverviewDashboard from 'components/analytics/financialOverview/dashboard/FinancialOverviewDashboard';
import { BalanceSheetOverviewDashboard } from 'components/analytics/balance-sheet-overview/dashboard';
import { FinancialsContainer } from 'components/financials/common';
import { CrosstabContainer } from 'components/financials/crosstab/CrosstabContainer';
import { ComparativeIncomeStatementGrid } from 'components/financials/comparative-income-statement';
import BalanceSheetGrid from 'components/financials/balanceSheet/components/grid/BalanceSheetGrid';
import RankingContainer from 'components/analytics/ranking/components/RankingDashboard';
import PropertySearch from 'components/property-search/PropertySearch';
import { EntityAttributesProvider } from 'contexts';
import bootstrap from 'state/bootstrap';
import TenantsDashboard from 'components/leases/tenants/TenantsDashboard';
import LeasesExpirationDashboard from 'components/leases/expirations/cards/leases-expiration/LeasesExpirationDashboard';
import RentRollDashboard from 'components/leases/expirations/cards/rent-roll/RentRollDashboard';
import { PropertySearchContainer } from 'components/property-search/PropertySearchContainer';
import PlanningHoldSellContainer from 'components/planning/hold-sell/PlanningHoldSellContainer';
import VarianceReportStatusContainer from 'components/financials/variance-report-status/components/VarianceReportStatusContainer';
import VarianceReportingContainer from 'components/financials/variance-report-status/components/VarianceReportingContainer';
import PlanningContainer from 'components/planning/PlanningContainer';
import PlanningCapitalProjectsContainer from 'components/planning/capital-projects/PlanningCapitalProjectsContainer';
import PlanningPrioritiesObjectivesContainer from 'components/planning/priorities-objectives/PlanningPrioritiesObjectivesContainer';
import PlanningServiceContractsContainer from 'components/planning/service-contracts/PlanningServiceContractsContainer';
import ReportGrid from 'components/reports/ReportGrid';
import ReportManager from 'components/reports/ReportManager';
import MyReportGrid from 'components/reports/MyReportGrid';
import ShortcutProvider from 'components/root/ShortcutProvider';
import EntityReportEditorContainer from 'components/reports/EntityReportEditorContainer';
import ReportExportPage from 'components/reports/ReportExportPage';
import { EntityOwnershipProvider } from '../contexts/entity-ownership/EntityOwnershipProvider';
import { connect, Provider, RootStateOrAny } from 'react-redux';
import {
    hasBalanceSheetEnabled,
    hasOwnershipPercentageEnabled,
    selectCurrentUser,
} from 'state/user/selectors';
import { selectUsersById } from 'state/users/selectors';
import AgedReceivablesContainer from 'components/agedReceivables/AgedReceivablesContainer';
import BudgetUploadContainer from 'components/budgetUpload/BudgetUploadContainer';
import { PermissionsGroupsProvider, AppFeaturePermissions } from 'contexts';
import PropertyProfileCommentsContainer from 'components/propertyProfile/PropertyProfileCommentsContainer';
import ReportWorkflowContainer from 'components/reports/ReportWorkflowContainer';
import TopTenantsDashboard from 'components/leases/tenants/top-tenants/TopTenantsDashboard';
import urlBuilder from '../utils/urlBuilder';
import UnitMixContainer from 'components/unitMix/UnitMixContainer';

interface RoutesParams {
    isBalanceSheetEnabled: boolean;
    isOwnershipPercentageEnabled: boolean;
    userPermissions: string[];
    store: any;
}

interface ProtectedRouteParams {
    path: string;
    exact?: boolean;
    render: () => JSX.Element;
}

const ProtectedRoute = ({ path, exact, render }: ProtectedRouteParams) => {
    const isAuthenticated = document.cookie.includes('CLIENT_ID_COOKIE');
    const getLoginUrlWithRedirectBack = () => {
        return window.location.pathname === RouteUrls.LOGIN
            ? window.location.href
            : urlBuilder.addQuery(RouteUrls.LOGIN, {
                  state: window.location.href,
                  idle: true,
              });
    };

    if (!isAuthenticated) {
        clearCookie('XSRF-TOKEN');
        clearCookie('CLIENT_ID_COOKIE');
        window.location.href = getLoginUrlWithRedirectBack();
        return <Redirect to={window.location.href} />;
    }

    return <Route path={path} exact={exact} render={render} />;
};

const Routes = ({
    isBalanceSheetEnabled,
    isOwnershipPercentageEnabled,
    userPermissions,
    store,
}: RoutesParams) => {
    const [bootstrapped, setBootstrapped] = useState<boolean>(false);
    const bootstrapState = async () => {
        await bootstrap(store.dispatch);
        setBootstrapped(true);
    };

    useEffect(() => {
        bootstrapState();
    }, []);

    return (
        <Provider store={store} key="provider">
            <div style={{ height: '100%', width: '100%' }}>
                <ShortcutProvider key="shortcutProvider">
                    <Router>
                        <App bootstrapped={bootstrapped}>
                            {bootstrapped ? (
                                <EntityAttributesProvider>
                                    <EntityOwnershipProvider
                                        isDisabled={
                                            !isOwnershipPercentageEnabled
                                        }
                                    >
                                        <PermissionsGroupsProvider
                                            userPermissions={userPermissions}
                                        >
                                            <Switch>
                                                {/* Entry point */}
                                                <Route
                                                    exact
                                                    path="/"
                                                    render={() => (
                                                        <Redirect
                                                            to={
                                                                RouteUrls.SEARCH
                                                            }
                                                        />
                                                    )}
                                                />

                                                <Route
                                                    exact
                                                    path={RouteUrls.PDF_RENDER}
                                                    render={() => (
                                                        <ReportExportPage />
                                                    )}
                                                />

                                                {/* Leases Routes */}
                                                <ProtectedRoute
                                                    key="property-leases"
                                                    path={
                                                        RouteUrls.LEASES_PERFORMANCE_OVERVIEW
                                                    }
                                                    render={() => (
                                                        <ExpirationDashboard />
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.LEASES_TENANTS
                                                    }
                                                    render={() => (
                                                        <Layout>
                                                            <TenantsDashboard />
                                                        </Layout>
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.LEASES_TOP_TENANTS
                                                    }
                                                    render={() => (
                                                        <Layout>
                                                            <TopTenantsDashboard />
                                                        </Layout>
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.LEASES_EXPIRATIONS_SCHEDULE
                                                    }
                                                    render={() => (
                                                        <LeasesExpirationDashboard />
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.LEASE_RENT_ROLL
                                                    }
                                                    render={() => (
                                                        <RentRollDashboard />
                                                    )}
                                                />

                                                {/* Analytics Routes*/}
                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.ANALYTICS_FINANCIAL_OVERVIEW
                                                    }
                                                    render={() => (
                                                        <AnalyticsContainer>
                                                            <FinancialOverviewDashboard />
                                                        </AnalyticsContainer>
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.ANALYTICS_RANKING
                                                    }
                                                    key="ranking"
                                                    render={() => (
                                                        <AnalyticsContainer>
                                                            <RankingContainer />
                                                        </AnalyticsContainer>
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.ANALYTICS_BALANCE_SHEET_OVERVIEW
                                                    }
                                                    render={() => (
                                                        <AnalyticsContainer>
                                                            <BalanceSheetOverviewDashboard />
                                                        </AnalyticsContainer>
                                                    )}
                                                />

                                                {/* Financials Routes */}
                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.FINANCIALS_COMPARATIVE_INCOME_STATEMENT
                                                    }
                                                    render={() => (
                                                        <FinancialsContainer>
                                                            <ComparativeIncomeStatementGrid />
                                                        </FinancialsContainer>
                                                    )}
                                                />
                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.FINANCIALS_BALANCE_SHEET
                                                    }
                                                    render={() => (
                                                        <FinancialsContainer>
                                                            <BalanceSheetGrid
                                                                isBalanceSheetEnabled={
                                                                    isBalanceSheetEnabled
                                                                }
                                                                showDisabledView={
                                                                    true
                                                                }
                                                                isPropertyProfile={
                                                                    false
                                                                }
                                                                entityCodes={[]}
                                                            />
                                                        </FinancialsContainer>
                                                    )}
                                                />
                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.FINANCIALS_CROSSTAB
                                                    }
                                                    render={() => (
                                                        <FinancialsContainer>
                                                            <CrosstabContainer />
                                                        </FinancialsContainer>
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.FINANCIALS_VARIANCE_REPORT_STATUS
                                                    }
                                                    render={() => (
                                                        <FinancialsContainer>
                                                            <VarianceReportStatusContainer />
                                                        </FinancialsContainer>
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.FINANCIALS_VARIANCE_REPORTING
                                                    }
                                                    render={() => (
                                                        <VarianceReportingContainer />
                                                    )}
                                                />

                                                {/* Property Profile Routes */}
                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.PROPERTY_PROFILE_DETAILS
                                                    }
                                                    render={() => (
                                                        <PropertyProfileCommentsContainer />
                                                    )}
                                                />

                                                {/* Planning Routes */}
                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.PLANNING_CAPITAL_TRACKER
                                                    }
                                                    render={() => (
                                                        <PlanningContainer>
                                                            <PlanningCapitalProjectsContainer />
                                                        </PlanningContainer>
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.PLANNING_HOLD_SELL
                                                    }
                                                    render={() => (
                                                        <PlanningContainer>
                                                            <PlanningHoldSellContainer />
                                                        </PlanningContainer>
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.PLANNING_PRIORITIES_AND_OBJECTIVES
                                                    }
                                                    render={() => (
                                                        <PlanningContainer>
                                                            <PlanningPrioritiesObjectivesContainer />
                                                        </PlanningContainer>
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.PLANNING_SERVICE_CONTRACTS
                                                    }
                                                    render={() => (
                                                        <PlanningContainer>
                                                            <PlanningServiceContractsContainer />
                                                        </PlanningContainer>
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.AGED_RECEIVABLES
                                                    }
                                                    render={() => (
                                                        <AgedReceivablesContainer />
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={RouteUrls.UNIT_MIX}
                                                    render={() => (
                                                        <UnitMixContainer />
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.BUDGET_UPLOAD
                                                    }
                                                    render={() => (
                                                        <PlanningContainer>
                                                            <BudgetUploadContainer />
                                                        </PlanningContainer>
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={`${RouteUrls.MY_REPORT_TEMPLATE}`}
                                                    exact
                                                    render={() => (
                                                        <Layout>
                                                            <MyReportGrid />
                                                        </Layout>
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={`${RouteUrls.REPORT_MANAGER}`}
                                                    render={() => {
                                                        return (
                                                            <Layout>
                                                                <ReportManager />
                                                            </Layout>
                                                        );
                                                    }}
                                                />

                                                <ProtectedRoute
                                                    path={`${RouteUrls.REPORT_TEMPLATE}`}
                                                    render={() => {
                                                        return (
                                                            <Layout>
                                                                <ReportGrid
                                                                    isWorkflowSteps={
                                                                        false
                                                                    }
                                                                />
                                                            </Layout>
                                                        );
                                                    }}
                                                />

                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.REPORT_CREATE
                                                    }
                                                    render={() => (
                                                        <ReportWorkflowContainer />
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.REPORT_UPDATE
                                                    }
                                                    render={() => (
                                                        <ReportWorkflowContainer />
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.ENTITY_REPORT_EDITOR
                                                    }
                                                    render={() => (
                                                        <EntityReportEditorContainer />
                                                    )}
                                                />

                                                {/* Other Routes */}
                                                <ProtectedRoute
                                                    path={RouteUrls.ATTRIBUTES}
                                                    render={() => (
                                                        <AttributesDashboard />
                                                    )}
                                                />

                                                <ProtectedRoute
                                                    path={RouteUrls.SEARCH}
                                                    render={() => (
                                                        <PropertySearchContainer>
                                                            <PropertySearch />
                                                        </PropertySearchContainer>
                                                    )}
                                                />
                                                <ProtectedRoute
                                                    path={
                                                        RouteUrls.SEGMENTATION
                                                    }
                                                    render={() => <Dashboard />}
                                                />
                                                <ProtectedRoute
                                                    path={RouteUrls.SETTINGS}
                                                    render={SettingsRoutes}
                                                />

                                                <Route
                                                    path="/error"
                                                    render={() => (
                                                        <ErrorPage>
                                                            {' '}
                                                            <NotFound />{' '}
                                                        </ErrorPage>
                                                    )}
                                                />
                                            </Switch>
                                        </PermissionsGroupsProvider>
                                    </EntityOwnershipProvider>
                                </EntityAttributesProvider>
                            ) : (
                                <></>
                            )}
                        </App>
                    </Router>
                </ShortcutProvider>
            </div>
        </Provider>
    );
};

const mapState = function (state: RootStateOrAny) {
    const currentUser = selectCurrentUser(state);
    const currentUserFeaturePermissions = selectUsersById(state, {
        userIds: [currentUser?.id],
    })[0]?.featurePermissions;
    const isBalanceSheetEnabled = hasBalanceSheetEnabled(state);

    return {
        isBalanceSheetEnabled,
        isOwnershipPercentageEnabled: hasOwnershipPercentageEnabled(state),
        userPermissions: currentUserFeaturePermissions?.length
            ? currentUserFeaturePermissions
            : Object.values(AppFeaturePermissions),
    };
};

export default connect(mapState)(Routes);
